import React, { 
    useState, 
    useEffect 
} from 'react';
import { 
    faPlus, 
    faSpinner,
    faEnvelope
} from '@fortawesome/free-solid-svg-icons';
import { 
    Container, 
    Row, 
    Col, 
    Form, 
    InputGroup, 
    Button, 
    Card, 
    Badge 
} from 'react-bootstrap';
import { 
    useParams, 
    useHistory, 
    Prompt 
} from 'react-router-dom';
import { Portuguese } from "flatpickr/dist/l10n/pt";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import MaskedInput from 'react-maskedinput';
import axios from 'axios';
import uniqid from 'uniqid';
import Flatpick from 'react-flatpickr';
import moment from 'moment-timezone';
import * as R from 'ramda';

// componentes
import { CadastroComponent } from './components';
import { intersectObject } from './../../../Funcoes';
import InputData from './../../../Painel/Componentes/InputData';
import InputHora from './../../../Painel/Componentes/InputHora';
import Participante from './Participante';
import ModalConfirmar from './../../../Painel/Componentes/ModalConfirmar';

import logoTipoAventura from './../../../../assets/rocaSeguroAventura.png';
import BotaoTabela from '../../../Painel/Componentes/BotaoTabela';
import './styles.css';

export default function Cadastro({
}) {

    const cartaOferta = useSelector(state => state.cartaOferta);
    const { jwt, clienteGrupoSuspenso, ...rest } = cartaOferta.dados; // separa o jwt dos dados restantes
    const timezoneCliente = moment.tz.guess();
    const utcCliente = moment().format('Z');

    // estados
    const history = useHistory();
    const { token } = useParams();
    const [dadosPadrao, alterarDadosPadrao] = useState(Object.assign({
        id: null,
        token: token || null,
        bloquearEnvioEmailsSeguroAventura: 'N',
        cartaOferta: null,
        finalizado: 'N',
        cancelado: 'N',
        localEvento: null,
        dataInicio: null,
        horaInicio: null,
        dataFinal: null,
        dataHoraInicioAtraso: null,
        horaFinal: '23:59:00',
        responsavel: null,
        telefone: null,
        email: null,
        tipoPessoa: 'F',
        cpf: null,
        cnpj: null,
        nomeEvento: null,
        linkParticipantesXls: null,
        linkInscricaoBloqueado: "N",
        descricao: null,
        razaoSocial: null,
        numeroApolice: null,
        realizouDownloadXls: "N",
        integrado: "N",
        integradoApi: "N",
        propriedade: "N",
        idCliente: null,
    }, rest));
    const [dados, alterarDados] = useState(Object.assign({}, dadosPadrao));
    const [dadosIniciais, alterarDadosIniciais] = useState(Object.assign({}, dadosPadrao));
    const [participantes, alterarParticipantes] = useState([]);
    const [salvando, alterarSalvando] = useState(false);
    const [carregando, alterarCarregando] = useState(true);
    const [mostrarModal, alterarMostarModal] = useState(false);
    const [erros, alterarErros] = useState({});
    const [leitura, alterarLeitura] = useState(false);
    const [liberarCampos, alterarLiberarCampos] = useState(false);
    const [habilitaCancelamento, alterarHabilitaCancelamento] = useState(false);
    const [enviandoEmails, alterarEnviandoEmails] = useState(false);
    const [salvandoUpload, alterarSalvandoUpload] = useState(false);
    const [salvandoDownload, alterarSalvandoDownload] = useState(false);
    const [dadosDiferentes, alterarDadosDiferentes] = useState(false);
    const [gerandoPdf, alterarGerandoPdf] = useState(false);
    const [participanteComEmail, alterarParticipanteComEmail] = useState(false);
    const [gerandoDadosEventoXls, alterarGerandoDadosEventoXls] = useState(false);
    const [habilitaCadastroFinalizado, alterarHabilitaCadastroFinalizado] = useState(true);
    const [confirmar, alterarConfirmar] = useState(null);
    const [assinatura, alterarAssinatura] = useState(null);

    // referencias
    const telefoneInput = React.createRef();
    const linkInput = React.createRef();
    const dataInicioPicker = React.createRef();
    const horarioInicioPicker = React.createRef();
    const dataFinalPicker = React.createRef();
    const horarioFinalPicker = React.createRef();
    const arquivoRef = React.useRef(null);

    // função para fazer com que cada palavra tenha sua primeira letra maiúscula
    function titleCase(str) {
        let splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
        }
        return splitStr.join(' '); 
    }
        
    // salva
    async function salvar() {
        
        alterarErros({});
        
        // mostra carregamento
        alterarSalvando(true);       
        
        // se o fuso horario do cliente for diferente e se tiver valores
        if(utcCliente !== '-03:00' && (dados.dataInicio && dados.horaInicio)) {

            // passa hora de inicio para horario de brasilia
            const horaInicioSP = moment.tz(new Date(dados.dataInicio + ' ' + dados.horaInicio), 'America/Sao_Paulo').format('HH:mm:ss');

            // atualiza dados
            dados.horaInicio = horaInicioSP;

        }
        
        try {

            // faz a requisição
            const { data } = await axios.post(`/seguroAventura`, {
                dados: intersectObject(dadosPadrao, dados),
                participantes
            });

            // se o fuso horario do cliente for diferente
            if(utcCliente !== '-03:00') {

                // passa hora de inicio para horario do cliente
                // eslint-disable-next-line no-useless-concat
                const horaInicioTZ = moment(new Date(data.dados.dataInicio + ' ' + data.dados.horaInicio + ' ' + '-03:00'), 'YYYY/MM/DD HH:mm:ss ZZ').tz(timezoneCliente).format('HH:mm:ss');

                // atualiza dados
                data.dados.horaInicio = horaInicioTZ;

            }

            alterarDados(data.dados);
            alterarDadosIniciais(data.dados);
            alterarParticipantes(data.participantes);
            alterarAssinatura(JSON.stringify({
                dados: data.dados,
                participantes: data.participantes
            }));

            // mensagem de sucesso
            toast(({ closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Seguro salvo com sucesso!</strong>
                    <button
                        onClick={closeToast}
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                {dados.finalizado === "N" &&
                    <div className="toast-body">
                        Para voltar a editar, utilize o código que chegou em seu email!
                    </div>
                }
            </>);

            // finalizado
            if (dados.finalizado === "S") {
                consultarToken();
            } else if (!token) {
                history.replace(`/formularios/propostaSeguro/evento/${data.dados.token}`);
            }

        } catch ({ response }) {
            if (response && response.status && response.status === 400) {
                alterarErros(response.data);

                // resgata chaves do objeto de erro
                const erroChaves = Object.keys(response.data);

                // verifica se o erro é do boleto
                const boletoChave = erroChaves.find(e => e === 'boleto');

                toast(<>
                    <div
                        style={{ background: '#ff6271' }}
                    >
                        <div className="toast-header">
                            <strong className="mr-auto">Houve alguns erros :(</strong>
                            <button
                                className="ml-2 mb-1 close btn-outline-light outline-0"
                            >
                            </button>
                        </div>
                        <div className="toast-body text-light">
                            {/* faz a verificação para retornar o erro do boleto */}
                            {boletoChave ? response.data[boletoChave] : "Revise os dados do evento!"}
                        </div>
                    </div>
                </>, { autoClose: 5000 }); // fecha automaticamente após 5s  

                // caso o erro foi relacioado aos participantes
                if (response.data.participantes) {

                    // percorre os participantes para registrar os erros
                    participantes.forEach((registro, pos) => {
                        if (response.data.participantes[registro.dados.id || registro.tempId]) {

                            toast(
                                <>
                                    <div
                                        style={{ background: '#ff6271' }}                                        
                                    >
                                        <div className="toast-header">
                                            <strong className="mr-auto">Participante com erros - Clicar para corrigir</strong>
                                            <button
                                                className="ml-2 mb-1 close btn-outline-light outline-0"
                                            >
                                            </button>
                                        </div>
                                        <div className="toast-body text-light">
                                            Revise os dados do participante <b>{registro.dados.nome}</b>!
                                        </div>
                                    </div>
                                </>, 
                                { 
                                    autoClose: 30000, 
                                    closeOnClick: true,
                                    toastId: registro.dados.id || registro.tempId,
                                    onClick: () => {

                                        // prepara o elemento
                                        let elemento = null;

                                        // encontra pelo id do participante
                                        elemento = document.getElementById("id_participante_"+registro.dados.id);
                                        
                                        // se não encontrou o elemento, tenta encontrar pelo id temporário
                                        if(!elemento) elemento = document.getElementById("id_participante_"+registro.tempId);
                                        
                                        // se encontrou o elemento, realiza o scroll ate ele
                                        if(elemento) elemento.scrollIntoView({ behavior: 'smooth' });

                                    }}                                
                            );
                        }
                    });
                }

                // caso foi erro do boleto, consulta evento novamente para não deixar salvar
                if(boletoChave) consultarToken();

            }
        } finally {
            alterarSalvando(false);
        }

    }

    // consultar token
    async function consultarToken() {
        alterarCarregando(true);

        try {

            // faz a requisição
            const { data } = await axios.get(`/seguroAventura/token/${token}`);

            // se o fuso horario do cliente for diferente
            if(utcCliente !== '-03:00') {

                // passa hora de inicio para horario do cliente
                // eslint-disable-next-line no-useless-concat
                const horaInicioTZ = moment(new Date(data.dados.dataInicio + ' ' + data.dados.horaInicio + ' ' + '-03:00'), 'YYYY/MM/DD HH:mm:ss ZZ').tz(timezoneCliente).format('HH:mm:ss');

                // atualiza dados
                data.dados.horaInicio = horaInicioTZ;

            }

            alterarDados(data.dados);
            alterarDadosPadrao({
                ...dadosPadrao,
                dataCadastro: data.dados.dataCadastro
            });
            alterarLiberarCampos(true);
            alterarDadosIniciais(data.dados);
            alterarParticipantes(data.participantes);
            alterarHabilitaCancelamento(data.habilitaCancelamento);
            alterarHabilitaCadastroFinalizado(data.permitidoAdicionarParticipante === 'S');
            alterarLeitura(!data.permiteEdicaoEvento);
            alterarAssinatura(JSON.stringify({
                dados: data.dados,
                participantes: data.participantes
            }));


            alterarCarregando(false);

        } catch ({ response }) {
            console.log(response);
        }

    }
    
    // monta próximo número de incrição do participante
    function montaProximoNumeroInscricao() {

        // resgata ultimo participante
        const ultimoParticipante = participantes[participantes.length - 1];        

        // resgata seu número de inscrição
        let numeroInscricao = ultimoParticipante.dados.numeroInscricao;

        // prepara numero de inscrição final
        let numeroInscricaoFinal = '';

        // prepara para verificação
        let passouZero = false;

        // percorre numero de inscrição
        for (let index = 0; index < numeroInscricao.length; index++) {
            
            // resgata numero atual
            const numero = numeroInscricao[index];
            
            // verifica se é zero e se não passou
            if(numero === "0" && !passouZero) {

                // altera variável
                passouZero = true;

                // vai para a próxima iteração
                continue;

            }
            
            // adiciona numero atual a variável
            numeroInscricaoFinal += numero;
            
        }
        
        // retorna númeri da inscrição final
        return parseInt(numeroInscricaoFinal) + 1;

    }

    // adicionar novo participante
    async function adicionarParticipante() {

        // faz o número da incrição
        const numeroInscricao =  (
            participantes.length > 0 ? montaProximoNumeroInscricao() : participantes.length + 1
        ).toString().padStart(3, '0');

        // monta objeto de novo participante
        const novoParticipante = {
            dados: {
                id: null,
                numeroInscricao,
                nome: null,
                cpf: null,
                dataNascimento: null,
                favorito: 'N',
                genero: 'M',
                email: null,
                telefone: null,
                cancelado: 'N',
                dataCancelado: null,
                contatoEmergenciaNome: null,
                contatoEmergenciaTelefone: null,
                restricoesDadosSaude: null,
                estrangeiro: 'N',
                numeroPassaporte: null,
                nacionalidade: null
            },
            tempId: uniqid(),
            habilitaCancelamento: false
        };

        let atualizarParticipantes = R.clone(participantes);
        atualizarParticipantes.push(novoParticipante);
        alterarParticipantes(atualizarParticipantes);

    }

    // cancelar o evento
    async function cancelarEvento() {

        alterarCarregando(true);

        try {

            // faz a requisição
            const { data } = await axios.post(`/seguroAventura/cancelar/${dados.token}`);
            alterarDados(data.dados);
            alterarDadosIniciais(data.dados);
            alterarParticipantes(data.participantes);

            // mensagem de sucesso
            toast(({ closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Seguro cancelado com sucesso!</strong>
                    <button
                        onClick={closeToast}
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                {dados.finalizado === "N" &&
                    <div className="toast-body">
                        Seguro foi cancelado!
                    </div>
                }
            </>);

        } catch ({ response }) {

            // mensagem de erro
            toast(<>
                <div
                    style={{ background: '#ff6271' }}
                >
                    <div className="toast-header">
                        <strong className="mr-auto">Seguro não pode ser cancelado!</strong>
                        <button
                            className="ml-2 mb-1 close btn-outline-light outline-0"
                        >
                        </button>
                    </div>
                    <div className="toast-body text-light">
                        {
                            Object.keys(response.data).map((erro) => {
                                return <p>{response.data[erro]}</p>
                            })
                        }
                    </div>
                </div>
            </>);

        } finally {
            alterarCarregando(false);
        }

    }

    // consulta participantes favoritados do cliente
    async function consultarParticipantesFavoritados() {

        // continua carregamento
        alterarCarregando(true);

        try {

            // faz a requisição
            const { data } = await axios.get('/seguroAventuraParticipante/favorito', {
                params: {
                    cartaOferta: cartaOferta.dados.cartaOferta
                }
            });

            // percorre registros
            const participantesFavoritados = data.map((participante, indice) => {

                // monta dados participante
                return {
                    dados: {
                        id: null,
                        numeroInscricao: (indice + 1).toString().padStart(3, '0'),
                        nome: participante.nome || null,
                        cpf: participante.cpf || null,
                        dataNascimento: participante.dataNascimento || null,
                        favorito: 'S',
                        genero: participante.genero || 'M',
                        email: participante.email || null,
                        telefone: participante.telefone || null,
                        cancelado: 'N',
                        dataCancelado: null,
                        contatoEmergenciaNome: participante.contatoEmergenciaNome,
                        contatoEmergenciaTelefone: participante.contatoEmergenciaTelefone,
                        restricoesDadosSaude: participante.restricoesDadosSaude,
                        estrangeiro: participante.estrangeiro || 'N',
                        numeroPassaporte: participante.numeroPassaporte || null,
                        nacionalidade: participante.nacionalidade || null
                    },
                    tempId: uniqid(),
                    habilitaCancelamento: false
                }

            });

            // adiciona participantes favoritos 
            alterarParticipantes(participantesFavoritados);
            alterarAssinatura(JSON.stringify({
                dados,
                participantes: participantesFavoritados
            }));

        } catch (e) {
            console.error(e);
        } finally {
            // para de carregar
            alterarCarregando(false);
            // libera campos
            alterarLiberarCampos(true);
        }

    }
    
    // função controladora para excluir o participante
    async function manipularExclusaoParticipante(registro, pos) {

        // confirma o cancelamento
        alterarConfirmar({
            titulo: `Excluir participante`,
            texto: `Tem certeza que deseja remover o participante do evento?`,
            textoBotaoCancelar: 'Não',
            textoBotaoConfirmar: 'Sim, excluir',
            clicouConfirmar: async () => {

                // bota pra carregar
                alterarCarregando(true);

                // verifica se o evento está criado e se possui participante
                if (dados.id && registro.dados.id) {

                    // chama a função para deletar participante do evento
                    await axios.delete(`/seguroAventuraParticipante/${registro.dados.tokenParticipante}`);

                    // verifica se possui cpf ou passaporte 
                } else if (registro.dados.cpf || registro.dados.numeroPassaporte) {

                    try {

                        // faz a requisição para remover os favoritos
                        await axios.post(`/seguroAventuraParticipante/removerFavorito`, {
                            cartaOferta: cartaOferta.dados.cartaOferta,
                            cpf: registro.dados.cpf,
                            numeroPassaporte: registro.dados.numeroPassaporte
                        });

                    } catch ({ response }) {
                        if (response && response.status && response.status === 400) {
                            alterarErros(response.data);
                            toast(<>
                                <div
                                    style={{ background: '#ff6271' }}
                                >
                                    <div className="toast-header">
                                        <strong className="mr-auto">Houve alguns erros :(</strong>
                                        <button
                                            className="ml-2 mb-1 close btn-outline-light outline-0"
                                        >
                                        </button>
                                    </div>
                                    <div className="toast-body text-light">
                                        Revise os dados do participante!
                                    </div>
                                </div>
                            </>);
                        }
                    }

                }

                let atualizarParticipantes = R.clone(participantes);
                atualizarParticipantes.splice(pos, 1);
                
                alterarParticipantes(atualizarParticipantes);
                alterarAssinatura(JSON.stringify({
                    dados,
                    participantes: atualizarParticipantes
                }));

                // esconde o carregar
                alterarCarregando(false);

                // remove a confirmação
                alterarConfirmar(null);


            }
        });

        // retira carregamento
        alterarCarregando(false);

    }
    
    // gerar pdf
    async function baixarPdfParticipantes() {
        
        // ativa carregamento
        alterarGerandoPdf(true);

        try {

            // gera pdf
            const { data } = await axios.get(`/seguroAventura/pdfComprovanteContratacao/${dados.token}`);
            
            // faz o download
            window.open(
                `${process.env.REACT_APP_URL_ARQUIVOS}/download.php?p=anexosEmail&f=${encodeURIComponent(data.temporario)}&n=${encodeURIComponent(data.nome)}`, 
                '_blank'
            );

        } catch({ response }) {

            console.error(response);

            toast(<>
                <div
                    style={{ background: '#ff6271' }}
                >
                    <div className="toast-header">
                        <strong className="mr-auto">Erro!</strong>
                        <button
                            className="ml-2 mb-1 close btn-outline-light outline-0"
                        >
                        </button>
                    </div>
                    <div className="toast-body text-light">
                        Houve um erro ao realizar o download 
                    </div>
                </div>
            </>);

        } finally {

            // desativa carregamento
            alterarGerandoPdf(false);

        }

    }

    // gera xls dos dados do evento
    async function baixarDadosEventoXls() {

        // ativa carregamento
        alterarGerandoDadosEventoXls(true);

        try {

            // gera xls
            const { data } = await axios.get(`/seguroAventura/xlsInformacoesEvento/${dados.token}`);
            
            // faz o download
            window.open(
                `${process.env.REACT_APP_URL_ARQUIVOS}/download.php?p=temp&f=${encodeURIComponent(data.temporario)}&n=${encodeURIComponent(data.nome)}`, 
                '_blank'
            );

        } catch({ response }) {

            console.error(response);

            toast(<>
                <div
                    style={{ background: '#ff6271' }}
                >
                    <div className="toast-header">
                        <strong className="mr-auto">Erro!</strong>
                        <button
                            className="ml-2 mb-1 close btn-outline-light outline-0"
                        >
                        </button>
                    </div>
                    <div className="toast-body text-light">
                        Houve um erro ao realizar o download 
                    </div>
                </div>
            </>);

        } finally {

            // desativa carregamento
            alterarGerandoDadosEventoXls(false);

        }

    }

    // fazer o upload do arquivo de participantes
    async function salvarXlsParticipantes(arquivoEvento=null) {
        
        // resgata valor do input
        const arquivo = arquivoEvento.target.files[0];
        
        // sem arquivos não executa
        if(
            !arquivo || 
            ![
                'application/vnd.ms-excel', 
                'text/csv', 
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ].includes(arquivo.type)
        ) return;

        // ativa carregamento
        alterarSalvandoUpload(true);

        // prepara para enviar
        let salvarXls = new FormData();
        salvarXls.append('arquivo', arquivo);
        salvarXls.append('id', dados.id);

        try {

            // faz a requisição para o upload
            const { data } = await axios.post('/seguroAventura/xlsParticipantesEvento', salvarXls, {
                headers: {'Content-type': 'multipart/form-data'}
            });   
            
            // verifica se possui erros
            if(data.erros) {

                // resgata as chaves do objeto e percorre
                Object.keys(data.erros).map((erro) => {

                    return toast(({ closeToast }) => <>
                        <div className="toast-header bg-danger text-white">
                            <strong className="mr-auto">O arquivo enviado possui erros!</strong>
                            <button
                                onClick={closeToast}
                                className="ml-2 mb-1 close btn-outline-light outline-0"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="toast-body bg-danger text-light">
                            {data.erros[erro]}    
                        </div>
                    </>);

                });                

            }

            // verifica se possui participantes
            if(data.participantes && data.participantes.length > 0) {

                // prepara para atualizar participante
                let atualizarParticipantes = R.clone(participantes);

                // percorre participantes
                data.participantes.forEach((participante, index) => {

                    // adiciona index
                    let indexMais = index + 1;
                    
                    // prepara dados padrão
                    const dadosPadrao = {
                        id: null,
                        numeroInscricao: (participantes.length + indexMais).toString().padStart(3, '0'),
                        nome: null,
                        cpf: null,
                        dataNascimento: null,
                        favorito: 'N',
                        genero: 'M',
                        email: null,
                        telefone: null,
                        cancelado: 'N',
                        dataCancelado: null,
                        estrangeiro: 'N',
                        numeroPassaporte: null,
                        nacionalidade: null
                    }
                    
                    // cria novo participante
                    const novoParticipante = {
                        dados: Object.assign(dadosPadrao, participante),
                        tempId: uniqid(),
                        habilitaCancelamento: false
                    };                

                    // adiciona participante ao array
                    atualizarParticipantes.push(novoParticipante);

                });

                // altera participantes
                alterarParticipantes(atualizarParticipantes);

            }

        } catch(e) {
            console.log(e);
        } finally {

            // para de carregar
            alterarSalvandoUpload(false);

        }                

    }

    // fazer o download do arquivo de participantes
    async function downloadXlsParticipantes() {        
                        
        // ativa carregamento
        alterarSalvandoDownload(true);

        // resgata nome do evento e retira seus espaços
        const nomeEvento = titleCase(dados.nomeEvento).replace(/\s/g, '');

        try {

            // faz a requisição para o upload
            const { data } = await axios.post('/seguroAventura/baixarParticipantesXls', {
                linkParticipantesXls: dados.linkParticipantesXls,
                idEvento: dados.id
            }, {
                responseType: 'blob'
            });            

            // resgata o retorno e cria uma url
            const href = URL.createObjectURL(data);
            
            // cria um elemento a
            const link = document.createElement('a');

            // atribui o link
            link.href = href;

            // altera o atributo e nome do arquivo
            link.setAttribute('download', `Participantes${nomeEvento}.xlsx`); //or any other extension

            // adiciona tag a ao body
            document.body.appendChild(link);

            // faz o clique na tag a para realizar o download
            link.click();

            // retira a tag a do body
            document.body.removeChild(link);

            // retira link
            URL.revokeObjectURL(href);

            // muda status do estado
            alterarDados({
                ...dados,
                realizouDownloadXls: "S"
            })

        } catch(e) {
            console.log('erro', e.response ? e.response : e);
            return toast(({ closeToast }) => <>
                <div className="toast-header bg-danger text-white">
                    <strong className="mr-auto">Houve um erro</strong>
                    <button
                        onClick={closeToast}
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body bg-danger text-light">
                    Ocorreu um erro ao realizar o download do arquivo
                </div>
            </>);
        } finally {

            // desativa carregamento
            alterarSalvandoDownload(false);

        }        

    }    

    // reenvia emails para os participantes do evento
    async function reenviarEmails() {

        // ativa carregamento do envio de emails
        alterarEnviandoEmails(true);

        try { 

            // faz a requisição
            await axios.post(`/seguroAventura/reenviarEmailParticipantes/${dados.token}`, {
                anexoComprovanteContratacao: "S"
            });

            // esconde modal
            alterarMostarModal(false);

            // mensagem de cadastro realizado com sucesso
            toast(({ closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Emails enviados!</strong>
                    <button
                        onClick={closeToast}
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </>, { autoClose: 5000 });

        } catch(e) {
            console.error(e);

            // verifica se veio retorno
            if (e.response && e.response.data) {

                const erros = e.response.data;

                // resgata as chaves do objeto e percorre
                Object.keys(erros).map((erro) => {

                    // cria toast
                    return toast(({ closeToast }) => <>
                        <div className="toast-header bg-danger text-white">
                            <strong className="mr-auto">Houveram erros no envio de emails!</strong>
                            <button
                                onClick={closeToast}
                                className="ml-2 mb-1 close btn-outline-light outline-0"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="toast-body  bg-danger text-white">
                            {erros[erro]}
                        </div>
                    </>, { autoClose: 5000 });

                });

            }

        } finally {

            // desativa emails
            alterarEnviandoEmails(false);
        }

    }

    useEffect(() => {

        // caso já esteja cadastrado
        if (token) {

            // consulta evento
            consultarToken();

        } else {

            // consulta participantes favoritados pelo cliente
            consultarParticipantesFavoritados();
            alterarDadosDiferentes(false);

            // se cliente estiver tentando cadastrar um evento
            // se estiver no grupo suspenso, não permite
            alterarLeitura(clienteGrupoSuspenso === "S");

        }

    }, []);

    useEffect(() => {
        alterarDadosDiferentes(assinatura !== JSON.stringify({ dados, participantes }));
    }, [assinatura, dados, participantes]);

    useEffect(() => {

        // verifica se possui data inicio e se não possui data final
        if(
            dados && 
            dados.dataInicio && 
            dados.dataInicio.length === 10 &&
            (
                !dados.dataFinal ||
                dados.dataFinal === ''
            )
        ) {
            // altera data final
            alterarDados({ 
                ...dados, 
                dataFinal: dados.dataInicio 
            });
        } 
        
    }, [dados.dataInicio])

    useEffect(() => {

        // verifica se possui participantes
        if(participantes.length > 0) {

            // percorre participantes e altera estado para verificar se há participantes com email no evento
            alterarParticipanteComEmail(participantes.some(p => p.dados.email !== null && p.dados.email !== ""));

        }   

    }, [participantes]);
    
    // se estiver carregando
    if (carregando) {
        return <div className="text-center"><FontAwesomeIcon icon={faSpinner} pulse /> Aguarde!</div>
    }

    // se o evento foi cancelado
    if (dados && dados.cancelado === 'S') {
        return <>
            <Container className="py-5">
                <Row>
                    <Col lg={{ span: 6, offset: 3 }}>
                        <Card className="border-0 bg-light text-center">
                            <Card.Body>
                                <p><b>Evento cancelado!</b></p>
                                <p>O evento <Badge variant="info"><b>{dados.codigo}</b></Badge> foi cancelado!</p>
                                <p>Esse evento não pode mais ser editado.</p>

                                <Button
                                    variant="padrao-azul"
                                    onClick={() => {
                                        history.push(`/formularios/propostaSeguro`);
                                    }}
                                >
                                    Voltar!
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    }
    
    return <CadastroComponent>

        {/* impede a saída se possuir itens a salvar */}
        {token && <Prompt
            when={
                (
                    assinatura &&
                    (
                        assinatura !== JSON.stringify({ dados, participantes })
                    )
                )
            }
            message={'Deseja sair dessa tela sem salvar as alterações?'}
        />}

        <Container>
            <Col lg={12} className="align-center">
                <div className="formulario">
                    <div className="logo mb-4 ">
                        <img src={logoTipoAventura} />
                    </div>
                </div>
            </Col>
            <Row>
                <Col
                    lg={6}
                    className="coluna responsavel"
                >
                    <div className="formulario">
                        <Card
                            className="coluna card-evento border-0 shadow-sm mb-3"
                        >
                            <Card.Body className='d-flex flex-column'>
                                <Row className='d-flex align-items-center justify-content-between w-100 m-0 mb-1'>
                                    <Link to='/formularios/propostaSeguro'>
                                        <Button variant="padrao-azul" className="botao-cadastrar p-1 px-2 mb-2 my-1" size="sm">
                                            <FontAwesomeIcon className="icone mt-1" icon={["fas", "angle-left"]} />
                                            <span>Voltar para eventos</span>
                                        </Button>
                                    </Link>

                                    {(dados.id && dados.integrado === 'N' && dados.finalizado === 'N') &&
                                        <Button
                                            variant="danger"
                                            className="p-1 px-2 my-1 ml-1 mr-0 mb-2"
                                            onClick={() => {

                                                // confirma o cancelamento
                                                alterarConfirmar({
                                                    titulo: `Cancelar evento`,
                                                    texto: `Tem certeza que deseja cancelar o evento?`,
                                                    clicouConfirmar: () => {
                                                        cancelarEvento();

                                                        // remove a confirmação
                                                        alterarConfirmar(null);
                                                    },
                                                    textoBotaoCancelar: 'Não',
                                                    textoBotaoConfirmar: 'Sim, cancelar'
                                                })
                                            }}
                                            style={{ fontSize: 14 }}
                                        >
                                            Cancelar evento
                                        </Button>
                                    }
                                </Row>

                                <Form.Group>
                                    <div className="d-flex align-items-center">
                                        <Form.Label className="flex-grow-1">
                                            {!liberarCampos ? 'Informe o Código da Carta Oferta*' : 'Código da apólice*'}
                                        </Form.Label>                                        
                                    </div>
                                    <InputGroup className="mb-3" size='sm'>
                                        <Form.Control
                                            value={dados.cartaOferta || ''}
                                            onChange={e => {
                                                alterarDados({ ...dados, cartaOferta: e.target.value });
                                            }}
                                            maxLength={100}
                                            size='sm'
                                            isInvalid={erros.cartaOferta}
                                            readOnly={leitura || liberarCampos}
                                        />
                                        {erros.cartaOferta &&
                                            <Form.Control.Feedback type="invalid">
                                                {erros.cartaOferta}
                                            </Form.Control.Feedback>
                                        }
                                    </InputGroup>
                                </Form.Group>

                                {dados.razaoSocial &&
                                    <>
                                        {(dados.tipoPessoa === "J") &&
                                            <Form.Group>
                                                <Form.Label>Empresa*</Form.Label>
                                                <Form.Control
                                                    value={dados.razaoSocial || ''}
                                                    onChange={e => {
                                                        alterarDados({ ...dados, razaoSocial: e.target.value })
                                                    }}
                                                    size="sm"
                                                    maxLength={100}
                                                    isInvalid={erros.razaoSocial}
                                                    // readOnly={leitura}
                                                    readOnly={true}
                                                />
                                                {erros.razaoSocial &&
                                                    <Form.Control.Feedback type="invalid">
                                                        {erros.razaoSocial}
                                                    </Form.Control.Feedback>
                                                }
                                            </Form.Group>
                                        }
                                    </>
                                }

                                {dados.responsavel &&
                                    <>
                                        <Form.Group>
                                            <Form.Label>Responsável*</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                maxLength={100}
                                                value={dados.responsavel || ''}
                                                onChange={e => {
                                                    alterarDados({ ...dados, responsavel: e.target.value });
                                                }}
                                                isInvalid={erros.responsavel}
                                                // readOnly={leitura}
                                                readOnly={true}
                                            />
                                            {erros.responsavel &&
                                                <Form.Control.Feedback type="invalid">
                                                    {erros.responsavel}
                                                </Form.Control.Feedback>
                                            }
                                        </Form.Group>
                                    </>
                                }

                                {!dados.razaoSocial ? <>
                                    <Form.Group>
                                        <Form.Label>Email*</Form.Label>
                                        <Form.Control
                                            value={dados.email || ''}
                                            maxLength='100'
                                            isInvalid={erros.email}
                                            onChange={e => {
                                                alterarDados({ ...dados, email: e.target.value });
                                            }}
                                            size="sm"
                                            // readOnly={leitura}
                                            readOnly={true}
                                        />
                                        {erros.email &&
                                            <Form.Control.Feedback type="invalid">
                                                {erros.email}
                                            </Form.Control.Feedback>
                                        }
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Telefone*</Form.Label>
                                        <MaskedInput
                                            className={["form-control form-control-sm", erros.telefone ? 'is-invalid' : ''].join(` `)}
                                            mask="(11) 11111-1111"
                                            value={dados.telefone || ''}
                                            ref={telefoneInput}
                                            onChange={(e) => {
                                                dados.telefone = telefoneInput.current.mask.getRawValue().split('_').join('')
                                                alterarDados({ ...dados });
                                            }}
                                            // readOnly={leitura}
                                            readOnly={true}
                                        />
                                        {erros.telefone &&
                                            <Form.Control.Feedback type="invalid">
                                                {erros.telefone}
                                            </Form.Control.Feedback>
                                        }
                                    </Form.Group>
                                </> : <Form.Row>
                                    <Col lg={8}>
                                        <Form.Group>
                                            <Form.Label>Email*</Form.Label>
                                            <Form.Control
                                                value={dados.email || ''}
                                                maxLength='100'
                                                isInvalid={erros.email}
                                                onChange={e => {
                                                    alterarDados({ ...dados, email: e.target.value });
                                                }}
                                                size="sm"
                                                // readOnly={leitura}
                                                readOnly={true}
                                            />
                                            {erros.email &&
                                                <Form.Control.Feedback type="invalid">
                                                    {erros.email}
                                                </Form.Control.Feedback>
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4} className="mb-2">
                                        <Form.Group>
                                            <Form.Label>Telefone*</Form.Label>
                                            <MaskedInput
                                                className={["form-control form-control-sm", erros.telefone ? 'is-invalid' : ''].join(` `)}
                                                mask="(11) 11111-1111"
                                                value={dados.telefone || ''}
                                                ref={telefoneInput}
                                                onChange={(e) => {
                                                    dados.telefone = telefoneInput.current.mask.getRawValue().split('_').join('')
                                                    alterarDados({ ...dados });
                                                }}
                                                // readOnly={leitura}
                                                readOnly={true}
                                            />
                                            {erros.telefone &&
                                                <Form.Control.Feedback type="invalid">
                                                    {erros.telefone}
                                                </Form.Control.Feedback>
                                            }
                                        </Form.Group>
                                    </Col>
                                </Form.Row>}
                                <div className='d-flex flex-row w-100 align-middle justify-content-between position-relative' style={{ flex: 1 }}>
                                    {(
                                        participantes.length > 0 && 
                                        participantes[0].dados &&
                                        participantes[0].dados.id && 
                                        dados.id
                                    ) && <div className='d-flex flex-row justify-content-start align-items-center gap-3' style={{ gap: 10 }}>
                                        <BotaoTabela 
                                            carregando={gerandoPdf}
                                            onClick={baixarPdfParticipantes}
                                            icone={["fas", "file-pdf"]}
                                            tamanhoIcone={'2x'}
                                            color={'#AA0000'}
                                        >
                                            Baixar Comprovante de Contratação
                                        </BotaoTabela>
                                        <BotaoTabela
                                            carregando={gerandoDadosEventoXls}
                                            onClick={baixarDadosEventoXls}
                                            icone={["fas", "file-excel"]} 
                                            tamanhoIcone={'2x'}
                                            color={'green'}
                                        >
                                            Baixar Dados do Evento
                                        </BotaoTabela>
                                    </div>}
                                    {(liberarCampos && habilitaCadastroFinalizado) && (participantes.length) > 4 && <Form.Row className='d-flex flex-row align-items-center justify-content-center m-0'>
                                        {dadosDiferentes && <>
                                            <p className='mb-0 ml-2' style={{ color: 'red', fontWeight: 'bold', fontSize: 14 }}>Clique aqui para salvar as alterações</p>
                                            <FontAwesomeIcon color='red' className="icone ml-2 mt-1" icon={["fas", "angle-right"]} />
                                        </>}
                                        <Button
                                            className="botao-cadastrar ml-2"
                                            variant="padrao-azul"
                                            disabled={salvando}
                                            onClick={salvar}
                                            size="sm"
                                        >
                                            {salvando ?
                                                <>
                                                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                                                    <span>Salvando</span>
                                                </> :
                                                <span>Salvar</span>
                                            }
                                        </Button>
                                    </Form.Row>}
                                </div>

                            </Card.Body>
                        </Card>

                    </div>
                </Col>
                {liberarCampos &&
                    <Col
                        lg={6}
                        className="coluna evento"
                    >
                        <div className="formulario">
                            <Card
                                className="coluna card-evento border-0 shadow-sm mb-3"
                            >
                                <Card.Body>

                                    <Row className='d-flex align-items-center justify-content-center w-100 m-0'>                                        
                                        <div className={'d-flex w-100 align-items-center titulo'} style={{ whiteSpace: 'nowrap' }}>
                                            <Card.Title
                                                className={['mb-1 flex-grow-1 m-0 text-center mw-100 overflow-hidden text-truncate font-weight-bold'].join(' ')}
                                            >
                                                Evento
                                            </Card.Title>
                                            {dadosIniciais.codigo &&
                                                <span class="ml-3 badge badge-success"><b>{dadosIniciais.codigo}</b></span>
                                            }
                                        </div>
                                    </Row>

                                    <Row>
                                        <Col lg={dadosIniciais.integrado === "N" ? 5 : 12}>
                                            <Form.Group>
                                                <Form.Label>Nome do Evento*</Form.Label>
                                                <Form.Control
                                                    value={dados.nomeEvento || ''}
                                                    onChange={e => {
                                                        alterarDados({ ...dados, nomeEvento: e.target.value });
                                                    }}
                                                    size="sm"
                                                    maxLength={150}
                                                    isInvalid={erros.nomeEvento}
                                                    readOnly={leitura}
                                                />
                                                {erros.nomeEvento &&
                                                    <Form.Control.Feedback type="invalid">
                                                        {erros.nomeEvento}
                                                    </Form.Control.Feedback>
                                                }
                                            </Form.Group>
                                        </Col>
                                        {dadosIniciais.integrado === "N" &&
                                            <Col lg={7}>
                                                <Form.Group>
                                                    <Form.Label>Início em*</Form.Label>
                                                    <div className="d-flex align-itens-center">
                                                        <Flatpick
                                                            ref={dataInicioPicker}
                                                            value={dados.dataInicio}
                                                            options={{
                                                                dateFormat: "Y-m-d",
                                                                altFormat: "d/m/Y",
                                                                altInput: false,
                                                                allowInput: false,
                                                                disableMobile: true,
                                                                locale: Portuguese,
                                                                onChange: (selectedDates, dateStr, instance) => {                                                                    
                                                                    if (dateStr === '') dateStr = null;
                                                                    alterarDados({ 
                                                                        ...dados, 
                                                                        dataInicio: dateStr 
                                                                    })
                                                                }
                                                            }}
                                                            render={
                                                                ({ defaultValue, value, onChange, ...props }, ref) => {
                                                                    return <input 
                                                                        onChange={(selectedDates, dateStr, instance) => {                                                                            
                                                                            alterarDados({ 
                                                                                ...dados, 
                                                                                dataInicio: dateStr 
                                                                            })
                                                                        }}
                                                                        value={dados.dataInicio} 
                                                                        style={{ 
                                                                            visibility: 'hidden', 
                                                                            position: 'absolute' 
                                                                        }} 
                                                                        {...props} 
                                                                        defaultValue={defaultValue} 
                                                                        ref={ref} 
                                                                    />
                                                                }
                                                            }
                                                        />
                                                        <InputData
                                                            data={dados.dataInicio}
                                                            onChange={(data) => {
                                                                alterarDados({ ...dados, dataInicio: data });
                                                            }}
                                                            size="sm"
                                                            isInvalid={erros.dataInicio}
                                                            readOnly={leitura || dados.propriedade === 'S'}
                                                        />
                                                        <FontAwesomeIcon
                                                            style={{
                                                                cursor: 'pointer',
                                                                alignSelf: 'center',
                                                                margin: '0px 8px',
                                                                opacity: leitura || dados.propriedade === 'S' ? 0.5 : 1
                                                            }}
                                                            icon={["fas", 'calendar-day']}
                                                            onClick={() => {
                                                                if (leitura || dados.propriedade === 'S') return;
                                                                dataInicioPicker.current.flatpickr.open()
                                                            }}
                                                        />
                                                        {erros.dataInicio &&
                                                            <p className="text-danger">
                                                                {erros.dataInicio}
                                                            </p>
                                                        }
                                                        <p className="p-1 m-0">as</p>
                                                        <Flatpick
                                                            ref={horarioInicioPicker}
                                                            value={dados.horaInicio}
                                                            options={{
                                                                noCalendar: true,
                                                                enableTime: true,
                                                                locale: Portuguese,
                                                                dateFormat: "H:i",
                                                                disableMobile: true,
                                                                defaultDate: "00:01",
                                                                onChange: (selectedDates, dateStr, instance) => {
                                                                    if (dateStr === '') dateStr = null;
                                                                    alterarDados({ ...dados, horaInicio: `${dateStr}:59` })
                                                                }
                                                            }}
                                                            render={
                                                                ({ defaultValue, value, onChange, ...props }, ref) => {
                                                                    return <input style={{ visibility: 'hidden', position: 'absolute' }} {...props} defaultValue={defaultValue} ref={ref} />
                                                                }
                                                            }
                                                        />
                                                        <InputHora
                                                            className='text-center'
                                                            value={dados.horaInicio}
                                                            onChange={(novaHora) => {                                                                
                                                                let formataHora = novaHora ? novaHora.split(":") : novaHora;
                                                                dados.horaInicio = formataHora ? `${formataHora[0]+':'+formataHora[1]+':59'}` : formataHora;                                                                
                                                                alterarDados({ ...dados });
                                                            }}
                                                            size="sm"
                                                            isInvalid={erros.horaInicio}
                                                            disabled={leitura}
                                                        />
                                                        <FontAwesomeIcon
                                                            style={{
                                                                cursor: 'pointer',
                                                                alignSelf: 'center',
                                                                margin: '0px 8px',
                                                                opacity: leitura ? 0.5 : 1
                                                            }}
                                                            icon={["fas", 'clock']}
                                                            onClick={() => {
                                                                if (leitura) return;
                                                                horarioInicioPicker.current.flatpickr.open()
                                                            }}
                                                        />
                                                    </div>
                                                    {erros.horaInicio &&
                                                        <p className="text-danger">
                                                            {erros.horaInicio}
                                                        </p>
                                                    }
                                                </Form.Group>
                                            </Col>
                                        }

                                    </Row>

                                    <Row>
                                        <Col lg={dadosIniciais.integrado === "N" ? 5 : 12}>
                                            <Form.Group>
                                                <Form.Label>Local do Evento*</Form.Label>
                                                <Form.Control
                                                    size="sm"
                                                    maxLength={400}
                                                    value={dados.localEvento || ''}
                                                    onChange={e => {
                                                        alterarDados({ ...dados, localEvento: e.target.value });
                                                    }}
                                                    isInvalid={erros.localEvento}
                                                    readOnly={leitura}
                                                />
                                                {erros.localEvento &&
                                                    <Form.Control.Feedback type="invalid">
                                                        {erros.localEvento}
                                                    </Form.Control.Feedback>
                                                }
                                            </Form.Group>
                                        </Col>
                                        {dadosIniciais.integrado === "N" &&
                                            <Col lg={7}>
                                                <Form.Group>
                                                    <Form.Label>Final em*</Form.Label>
                                                    <div className="d-flex align-itens-center">
                                                        <Flatpick
                                                            ref={dataFinalPicker}
                                                            value={dados.dataFinal}
                                                            options={{
                                                                dateFormat: "Y-m-d",
                                                                altFormat: "d/m/Y",
                                                                altInput: false,
                                                                allowInput: false,
                                                                disableMobile: true,
                                                                locale: Portuguese,
                                                                onChange: (date, dateStr, instance) => {
                                                                    if (dateStr === '') dateStr = null;
                                                                    alterarDados({ ...dados, dataFinal: dateStr })
                                                                }
                                                            }}
                                                            render={
                                                                ({ defaultValue, value, onChange, ...props }, ref) => {
                                                                    return <input style={{ visibility: 'hidden', position: 'absolute' }} {...props} defaultValue={defaultValue} ref={ref} />
                                                                }
                                                            }
                                                        />
                                                        <InputData
                                                            data={dados.dataFinal}
                                                            onChange={(data) => {
                                                                alterarDados({ ...dados, dataFinal: data });
                                                            }}
                                                            size="sm"
                                                            isInvalid={erros.dataFinal}
                                                            readOnly={leitura || dados.propriedade === 'S'}
                                                        />
                                                        <FontAwesomeIcon
                                                            style={{
                                                                cursor: 'pointer',
                                                                alignSelf: 'center',
                                                                margin: '0px 8px',
                                                                opacity: leitura || dados.propriedade === 'S' ? 0.5 : 1
                                                            }}
                                                            icon={["fas", 'calendar-day']}
                                                            onClick={() => {
                                                                if (leitura || dados.propriedade === 'S') return;
                                                                dataFinalPicker.current.flatpickr.open()
                                                            }}
                                                        />
                                                        <p className="p-1 m-0">as</p>
                                                        <Flatpick
                                                            ref={horarioFinalPicker}
                                                            value={dados.horaFinal}
                                                            options={{
                                                                noCalendar: true,
                                                                enableTime: true,
                                                                locale: Portuguese,
                                                                disableMobile: true,
                                                                dateFormat: "H:i",
                                                                defaultDate: "23:59",
                                                                onChange: (selectedDates, dateStr, instance) => {
                                                                    if (dateStr === '') dateStr = null;
                                                                    alterarDados({ ...dados, horaFinal: `${dateStr}:00` })
                                                                }
                                                            }}
                                                            render={
                                                                ({ defaultValue, value, onChange, ...props }, ref) => {
                                                                    return <input style={{ visibility: 'hidden', position: 'absolute' }} {...props} defaultValue={defaultValue} ref={ref} />
                                                                }
                                                            }
                                                        />
                                                        <InputHora
                                                            className='text-center'
                                                            value={dados.horaFinal}
                                                            onChange={novaHora => {
                                                                dados.horaFinal = novaHora;
                                                                alterarDados({ ...dados });
                                                            }}
                                                            size="sm"
                                                            isInvalid={erros.dataFinal}
                                                            disabled={true}
                                                        />
                                                        <FontAwesomeIcon
                                                            style={{
                                                                cursor: 'pointer',
                                                                alignSelf: 'center',
                                                                margin: '0px 8px',
                                                                opacity: 0.5
                                                            }}
                                                            icon={["fas", 'clock']}
                                                        />
                                                    </div>
                                                    {erros.dataFinal &&
                                                        <p className="text-danger">
                                                            {erros.dataFinal}
                                                        </p>
                                                    }
                                                </Form.Group>
                                            </Col>
                                        }
                                    </Row>

                                    <Form.Group>
                                        <Form.Label>Descrição do Evento*</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            size="sm"
                                            value={dados.descricao || ''}
                                            onChange={e => {
                                                alterarDados({ 
                                                    ...dados, 
                                                    descricao: e.target.value 
                                                });
                                            }}
                                            minrow={3}
                                            maxLength={500}
                                            isInvalid={erros.descricao}
                                            readOnly={leitura}
                                        />
                                        {erros.descricao &&
                                            <Form.Control.Feedback type="invalid">
                                                {erros.descricao}
                                            </Form.Control.Feedback>
                                        }
                                    </Form.Group>

                                    {(dados.token && habilitaCadastroFinalizado) &&
                                        <Form.Group>
                                            <Row style={{ flexWrap: 'nowrap' }}>
                                                <Col 
                                                    lg={7}
                                                    style={{
                                                        paddingRight: 0
                                                    }}
                                                >
                                                    {dados.propriedade === "N" ?
                                                        <Form.Group>
                                                            <Form.Label>Link para o cliente realizar a inscrição</Form.Label>
                                                            <InputGroup className="mb-3" size="sm">
                                                                <Form.Control
                                                                    readOnly={true}
                                                                    value={`${window.location.origin}/formularios/propostaSeguro/${dados.token}/cliente`}
                                                                    size="sm"
                                                                    ref={linkInput}
                                                                />
                                                                <InputGroup.Append>
                                                                    <Button
                                                                        variant="padrao-azul"
                                                                        size="sm"
                                                                        onClick={() => {
                                                                            linkInput.current.select();
                                                                            document.execCommand('copy');
                                                                        }}
                                                                    >Copiar</Button>
                                                                </InputGroup.Append>
                                                            </InputGroup>                                                    
                                                        </Form.Group>
                                                    : <></>}
                                                </Col>
                                                <Col 
                                                    lg={5}
                                                    style={{
                                                        paddingLeft: 0
                                                    }}
                                                >
                                                    <Form.Switch
                                                        type="switch"
                                                        label={dados.linkInscricaoBloqueado === "N" ? "Inscrições via Link habilitado" : "Inscrições via Link desabilitado"}                            
                                                        color='white'
                                                        checked={dados.linkInscricaoBloqueado === "N"}
                                                        onChange={() => {
                                                            alterarDados({
                                                                ...dados,
                                                                linkInscricaoBloqueado: dados.linkInscricaoBloqueado === "N" ? "S" : "N"
                                                            });
                                                        }}
                                                />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    }
                                    
                                    <Form.Group>
                                        <Row className='justify-content-between m-0'>
                                            {(dados.id && dados.linkParticipantesXls) && <div
                                                className='d-flex direction-row align-items-center justify-content-start'
                                            >
                                                <div 
                                                    className='d-flex overflow-hidden align-items-center justify-content-start p-2 rounded container-arquivo'
                                                >                                            
                                                    <FontAwesomeIcon 
                                                        style={{ color: 'green' }} 
                                                        className="icone" 
                                                        icon={["fas", "file-excel"]} 
                                                        size={"1x"}
                                                    />
                                                    <Form.Label 
                                                        className='ml-2 mb-0 text-nowrap'                                            
                                                    >
                                                        Arquivo xls participantes
                                                    </Form.Label>
                                                </div>   
                                                {(dados.realizouDownloadXls === "S") && <div>
                                                    <BotaoTabela 
                                                        carregando={salvandoUpload}
                                                        onClick={() => {
                                                            if(arquivoRef && arquivoRef.current) arquivoRef.current.click();
                                                        }}
                                                        icone={["fas", "upload"]}
                                                        tamanhoIcone={22}
                                                        color={'#000'}
                                                        classe={"botao-arquivo"}
                                                        estiloBotao={{
                                                            height: 40,
                                                            border: '1px solid #284787',
                                                            padding: '0.5rem 10px'
                                                        }}
                                                    >                                            
                                                        Fazer o upload dos participantes
                                                    </BotaoTabela>
                                                    <Form.File 
                                                        className='d-none' 
                                                        ref={arquivoRef} 
                                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                        onChange={salvarXlsParticipantes}
                                                    />                                                                                        
                                                </div>}
                                                <BotaoTabela 
                                                    carregando={salvandoDownload}
                                                    onClick={downloadXlsParticipantes}
                                                    tamanhoIcone={22}
                                                    color={'#000'}
                                                    icone={["fas", "download"]}
                                                    classe={"botao-arquivo"}
                                                    estiloBotao={{
                                                        height: 40,
                                                        border: '1px solid #284787',
                                                        padding: '0.5rem 10px',
                                                        borderLeftWidth: dados.realizouDownloadXls === "S" ? 0 : '1px solid #284787',
                                                        borderTopRightRadius: 5,
                                                        borderBottomRightRadius: 5
                                                    }}
                                                >
                                                    Fazer o download dos participantes
                                                </BotaoTabela>
                                            </div>}

                                            {(dados.id && participantes.length > 0 && participanteComEmail) && <Button
                                                size="sm"
                                                variant="info"
                                                className="rounded-pill"
                                                onClick={() => alterarMostarModal(true)}
                                            >
                                                <FontAwesomeIcon 
                                                    className="mr-2" 
                                                    icon={faEnvelope} 
                                                />
                                                <span>
                                                    Reenviar todos os e-mails
                                                </span>
                                            </Button>}
                                        </Row>
                                    </Form.Group>

                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                }
            </Row>

            {liberarCampos &&
                <>
                    <div className="participantes mb-3">
                        {participantes.map((registro, pos) =>
                            <Participante
                                key={registro.dados.id || registro.tempId}
                                tempId={registro.tempId ?? null}
                                seguroDados={dados}
                                seguroDadosIniciais={dadosIniciais}
                                dados={registro.dados}
                                leituraParticipante={leitura}
                                habilitaCancelamento={registro.habilitaCancelamento}
                                habilitaCadastroFinalizado={habilitaCadastroFinalizado}
                                alterarDados={(novosDados) => {
                                    let atualizarParticipantes = R.clone(participantes);
                                    atualizarParticipantes[pos].dados = novosDados;
                                    alterarParticipantes(atualizarParticipantes);
                                }}
                                erros={(erros.participantes && erros.participantes[registro.dados.id || registro.tempId]) ? erros.participantes[registro.dados.id || registro.tempId] : []}
                                excluir={() => {

                                    // verifica se foi passado cpf e passaporte
                                    if (
                                        (
                                            registro.dados.cpf &&
                                            registro.dados.cpf !== ""
                                        ) || (
                                            registro.dados.numeroPassaporte &&
                                            registro.dados.numeroPassaporte !== ""
                                        )
                                    ) {
                                        return manipularExclusaoParticipante(registro, pos);
                                    }

                                    let atualizarParticipantes = R.clone(participantes);
                                    atualizarParticipantes.splice(pos, 1);

                                    alterarParticipantes(atualizarParticipantes);
                                    alterarAssinatura(JSON.stringify({
                                        dados,
                                        participantes: atualizarParticipantes
                                    }));

                                }}
                            />
                        )}

                        {(habilitaCadastroFinalizado && dados.propriedade === 'N' && dados.id) &&
                            <div>
                                <Button
                                    className="rounded-pill p-2 px-4"
                                    variant="padrao-azul"
                                    onClick={adicionarParticipante}
                                >
                                    <FontAwesomeIcon icon={faPlus} size="1x" className="mr-2" />
                                    <span>Novo <br /> Participante</span>
                                </Button>
                            </div>
                        }
                    </div>

                    {(habilitaCadastroFinalizado && !leitura) &&
                        <>
                            <Container className="mt-4">
                                <Form.Group className="d-flex flex-row align-center justify-content-center my-4">
                                    {dadosDiferentes && <Form.Row className='d-flex flex-row align-items-center justify-content-center m-0'>
                                        <p className='m-0' style={{ color: 'red', fontWeight: 'bold' }}>Clique aqui para salvar as alterações</p>
                                        <FontAwesomeIcon color='red' className="icone ml-2 mt-1" icon={["fas", "angle-right"]} />
                                    </Form.Row>}
                                    <Button className='ml-1' variant="padrao-azul" disabled={salvando} onClick={salvar} size="lg">
                                        {salvando ?
                                            <>
                                                <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                                                <span>Salvando</span>
                                            </> :
                                            <span>Salvar</span>
                                        }
                                    </Button>
                                </Form.Group>
                            </Container>
                        </>
                    }

                </>
            }
        </Container>

        <ModalConfirmar
            mostrar={confirmar ? true : false}
            clicouCancelar={() => {
                alterarConfirmar(null)
            }}
            {...confirmar ? confirmar : {}}
        />

        <ModalConfirmar
            mostrar={mostrarModal}
            titulo={'Reenviar email'}
            texto={`Você deseja reenviar os emails para os participantes?`}
            clicouCancelar={() => alterarMostarModal(false)}
            clicouConfirmar={() => reenviarEmails()}
            carregando={enviandoEmails}
        />

    </CadastroComponent>

}